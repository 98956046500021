import { createAction } from '@reduxjs/toolkit';

import { isolatedDialog } from 'Addons/fitmentSearch/isolatedKeys.ts';

import type { IsolatedKey } from 'Addons/fitmentSearch/isolatedKeys.ts';
import type { FacetCollection, FacetValueFull, Vehicle } from 'Models/index.ts';
import type { VehicleValue } from 'Models/vehicle.ts';
import type { ServerModel } from 'Modules/serverApi/types';

export const sendInitFitmentSearchRequest = createAction('SEND_INIT_FITMENT_SEARCH_REQUEST');

export const clearVehicleDialogRequest = createAction('CLEAR_VEHICLE_DIALOG_REQUEST');
export const sendVehicleDialogRequest = createAction('SEND_VEHICLE_DIALOG_REQUEST');
export const setVehicleDialogSelection = createAction<VehicleValue[]>('SET_VEHICLE_DIALOG_SELECTION');

export const replaceVehicleValue = createAction(
  'REPLACE_VEHICLE_VALUE',
  (
    value: FacetValueFull,
    isolatedKey: IsolatedKey = null,
    fitmentFields: string[],
    meta: {
      isAutoVehicleSelectionDisabled?: boolean;
      isPartialMode?: boolean;
      sendIsolatedRequest?: boolean;
      doNotRedirectOnVehicleSelect?: boolean;
      redirectUrl?: string;
      vehicleWidgetName?: string;
      isVehicleSelectionIsolated?: boolean;
    } = {},
  ) => ({
    payload: value,
    meta: {
      sendRequest: !isolatedKey,
      allowSearchSameAsUri: true,
      stayOnPage: true,
      isolatedKey,
      fitmentFields,
      responseMeta: {
        isAutoVehicleSelectionDisabled: meta.isAutoVehicleSelectionDisabled,
        isPartialMode: meta.isPartialMode,
        doNotRedirectOnVehicleSelect: meta.doNotRedirectOnVehicleSelect,
        redirectUrl: meta.redirectUrl,
        vehicleWidgetName: meta.vehicleWidgetName,
        isVehicleSelectionIsolated: meta.isVehicleSelectionIsolated,
      },
      extra: { vehicleValueJustReplaced: true },
      sendIsolatedRequest: meta.sendIsolatedRequest,
    },
  }),
);

export const discardVehicleValue = createAction(
  'DISCARD_VEHICLE_VALUE',
  (value: FacetValueFull, isolatedKey: IsolatedKey = null) => ({
    payload: value,
    meta: {
      isolatedKey,
      sendRequest: !isolatedKey,
      allowSearchSameAsUri: true,
      stayOnPage: true,
      extra: { vehicleValueJustReplaced: true },
      isInDialog: isolatedKey === isolatedDialog,
    },
  }),
);

export const clearVerifyFitmentRequest = createAction('CLEAR_VERIFY_FITMENT_REQUEST');
export const sendVerifyFitmentRequest = createAction(
  'SEND_VERIFY_FITMENT_REQUEST',
  (meta: { onInit?: boolean; productId?: number; vehicle: Vehicle }) => ({
    payload: undefined,
    meta,
  }),
);
export const setVerifyFitmentProductId = createAction<number>('SET_VERIFY_FITMENT_PRODUCT_ID');
export const setVerifyFitmentProduct = createAction<ServerModel.SearchItem | null>('SET_VERIFY_FITMENT_ITEM');
export const setVerifyFitmentFacets = createAction(
  'SET_VERIFY_FITMENT_FACETS',
  (
    payload: FacetCollection,
    meta: {
      isAutoVehicleSelectionDisabled?: boolean;
      onInit: boolean;
      vehicleJustDiscarded: boolean;
      vehicleJustSelected: boolean;
    },
  ) => ({
    payload,
    meta,
  }),
);
export const replaceVerifyFitmentVehicleValue = createAction(
  'REPLACE_VERIFY_FITMENT_VEHICLE_VALUE',
  (payload: FacetValueFull, meta: { isAutoVehicleSelectionDisabled?: boolean; fitmentFields: string[] }) => ({
    payload,
    meta,
  }),
);
