export function trimEndSlash(str) {
  return str?.endsWith('/') ? str.slice(0, -1) : str;
}

export const removeSpecialSymbols = (str) => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[.,/\\!@#$%^&*()_+={}[\]:;"'<>?|`~\s-]/g, '');
};
