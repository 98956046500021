import shopifyGenericDefaults from '../shopify-generic/config.js';

window.Convermax.getVehicleInfoLink = (fieldName, value) => {
  if (fieldName.toLowerCase().includes('tire')) {
    if (value.match(/^(\d{2}|\d{3})\/\d{2}-R\d{2}$/g)) {
      // e.g. '130/70-R18' or '90/90-R21
      const parts = value.split('-R');
      const size = parts[0].split('/');

      return `/pages/search?product_type/Tire/tire_width/${size[0]}/tire_aspect/${size[1]}/tire_rim/${parts[1]}`;
    }
    return null;
  } else if (fieldName.toLowerCase() === 'Owners Manual') {
    // link to manual
    return value;
  }

  // SKU e.g. 'DCPR6E' or 'CTX20HL-BS'
  return `/pages/search?query=${value}`;
};

export default {
  ...shopifyGenericDefaults,
  includes: ['shopify-generic'],
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => !['Facets', 'FacetDialog'].includes(w.name)),
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields: shopifyGenericDefaults.Widgets.find((w) => w.name === 'Facets').ignoreFields,
      initCollapsed: true,
      initExpandedFacets: ['Shop By', 'product_type'],
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialogVehicleSpecs',
      initCollapsed: true,
      initExpandedFacets: ['Shop By', 'product_type'],
    },
    {
      name: 'VehicleInfo',
    },
    {
      name: 'RelatedSchematicsProducts',
      type: 'RelatedItems',
      location: { selector: '#cm-schematic-products', class: 'cm_schematic-products' },
      template: 'product/relatedItems',
      count: 50,
      useManualItems: true,
      requestExtra: { schematicProducts: true },
    },
  ],
};
